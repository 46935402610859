import { Heading as H, HeadingProps as HProps } from "common/components/Heading"
import clsx from "classnames"

export interface HeadingProps {
  text: string
  level?: HProps["level"]
}

export const headingBlockLevelClassNames = (level: number, hasContent: boolean) =>
  clsx({
    "mt-12 mb-4 first:mt-0 lg:first:mt-12": hasContent,
    "lg:mb-8": hasContent && level === 1,
  })

export const Heading = ({ text, level = 1 }: HeadingProps) => {
  const trimmedText = text?.trim()
  return (
    <div className={headingBlockLevelClassNames(level, trimmedText.length > 0)}>
      <H level={level}>{trimmedText}</H>
    </div>
  )
}
